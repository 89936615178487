import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { LocationOn, Email, Phone } from '@material-ui/icons'

import LogoLight from '../../atoms/images/LogoLight'
import PaddedContent from '../../atoms/container/PaddedContent'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'

import facebook from '../../../assets/images/social/facebook.svg'
import linkedin from '../../../assets/images/social/linkedin.svg'
import twitter from '../../../assets/images/social/twitter.svg'

const DarkFooterStyled = styled(Grid)`
  padding: 50px 20px;
  background: linear-gradient(#283048 10%, #19231a 90%);
`

const ContactContainerStyled = styled(MaxWidthContainer)`
  ${PaddedContent};

  h2 {
    margin: 0;
  }

  p {
    font-size: 20px;
  }

  a {
    display: flex;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
    transition: all ease 0.5s;
  }

  a:hover {
    color: ${({ theme }) => theme.palette.brandGold};
  }

  a svg {
    height: 25px;
    margin: 3px 7px 0px 0;
    fill: ${({ theme }) => theme.palette.brandGold};
  }
`

const LogoContainer = styled(Grid)`
  && .gatsby-image-wrapper {
    max-width: 200px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && .gatsby-image-wrapper {
      margin: 0 auto;
      max-width: 180px;
    }
  }
`

const SocialMediaContainer = styled(Grid)`
  text-align: right;
  margin: 15px 0 10px 0;

  img {
    max-width: 25px;
    margin: 6px 7px;
    -webkit-transition: opacity 0.5s ease;
    transition: transform 0.5s ease;
  }

  img:hover {
    transform: scale(1.1);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      margin-top: 15px;
      text-align: center;
    }
  }
`

const CopyContentStyled = styled.div`
  max-width: 450px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;

  p {
    color: #fff;
    opacity: 0.75;
    font-size: 14px;
    line-height: 1.2;
  }

  p:first-child {
    font-size: 12px;
  }
`

const VerticalAlign = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  p {
    max-width: 600px;
  }
`

const FormContainerStyled = styled.div`
  padding: 35px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 35px 0;
  }
`

const DarkFooter = () => {
  return (
    <Fragment>
      <ContactContainerStyled container id="contact">
        <VerticalAlign item md={6}>
          <h2>Ready to get started?</h2>
          <p>
            Enquire now to get started on the journey to owning your own
            successful business brokerage!
          </p>

          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              itemProp="email"
              href="mailto:david@twba.co.nz">
              <Email />
              <div>mark@twba.co.nz</div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              itemProp="telephone"
              href="tel:0800777731">
              <Phone />
              <div>0800 777 731</div>
            </a>
          </div>
        </VerticalAlign>
        <Grid item md={6} xs={12}>
          <FormContainerStyled
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
                <div class="pipedriveWebForms" data-pd-webforms="https://pipedrivewebforms.com/form/28bb61053576b2ad6f85d43f067ad29d5636067"></div>
              `,
            }}
          />
        </Grid>
      </ContactContainerStyled>
      <DarkFooterStyled>
        <MaxWidthContainer container>
          <LogoContainer item xs={12} md={4}>
            <LogoLight />
          </LogoContainer>
          <Grid justify="flex-end" item xs={12} md={8} container>
            <SocialMediaContainer item xs={12} className="social-container">
              <a
                href="https://www.facebook.com/Transworld-Business-Advisors-New-Zealand-1497733550330953"
                target="_blank"
                rel="noopener noreferrer">
                <img src={facebook} alt="facebook icon" />
              </a>
              <a
                href="https://www.linkedin.com/in/davidnewporttransworldbusinessadvisorsnewzealand"
                target="_blank"
                rel="noopener noreferrer">
                <img src={linkedin} alt="linkedin icon" />
              </a>
              <a
                href="https://twitter.com/tworldbusiness"
                target="_blank"
                rel="noopener noreferrer">
                <img src={twitter} alt="twitter icon" />
              </a>
            </SocialMediaContainer>
          </Grid>
          <Grid item xs={12}>
            <CopyContentStyled>
              <p>
                This advertisement is not an offer to sell a franchise. Any
                offer to sell this franchise will be made by a Franchise
                Disclosure Document and only following registration by
                Transworld prior to sale.
              </p>
              <p>
                Copyright © 2019 Transworld Business Advisors.<br />
                All Rights reserved.
              </p>
              <p>Licensed REAA 2008</p>
            </CopyContentStyled>
          </Grid>
        </MaxWidthContainer>
      </DarkFooterStyled>
    </Fragment>
  )
}

export default DarkFooter
