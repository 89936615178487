import React, { useState } from 'react'
import styled from 'styled-components'
import { Grid, Dialog, Slide } from '@material-ui/core'

import { CloseIcon } from '../images/SVGIcons'
import TworldButton from '../buttons/TworldButton'

const Transition = React.forwardRef((props, ref) => {
  return <Slide ref={ref} {...props} />
})

const ContentGrid = styled(Grid)`
  min-width: 400px;
  min-height: 500px;
  padding: 60px 60px 40px 60px;
`

const CloseIconStyled = styled(CloseIcon)`
  top: 20px;
  right: 20px;
  cursor: pointer;
  position: absolute;
`

const DialogStyled = styled(Dialog)`
  && div:nth-child(3) > div:nth-child(1) {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.palette.white};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      & {
        margin: 0;
        width: 100%;
        max-width: 90%;
      }
    }
  }

  && .zeal-backdrop {
    background-color: ${({ theme }) => theme.palette.backdrop};
    transition: opacity 0.3s ease, background-color 0.3s ease !important;
  }

  && .content-container {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      & {
        padding: 40px 25px 0px 25px;
      }
    }
  }
`

const TworldButtonStyled = styled(TworldButton)`
  && {
    margin-left: 12px;
  }
`

export default function RequestInfoDialog() {
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  return (
    <div>
      <TworldButtonStyled
        onClick={handleOpen}
        fullWidth={false}
        text="Request Info"
      />
      <DialogStyled
        keepMounted
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}>
        <CloseIconStyled onClick={handleClose} />
        <ContentGrid
          container
          justify="space-around"
          className="content-container"
          direction="column">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
                <div class="pipedriveWebForms" data-pd-webforms="https://pipedrivewebforms.com/form/28bb61053576b2ad6f85d43f067ad29d5636067"><script src="https://cdn.pipedriveassets.com/web-form-assets/webforms.min.js"></script></div>
              `,
            }}
          />
        </ContentGrid>
      </DialogStyled>
    </div>
  )
}
