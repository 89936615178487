import { css } from 'styled-components'

const MobileSectionSeperateImage = css`
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
  border-radius: 8px;
`

export default MobileSectionSeperateImage
