import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const ImageStyled = styled(Img)`
  && {
    top: 0px;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute !important;
    transform: translateX(0px) scale(1);
  }
`

const Overlay = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.black};
`

const HeroImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "header-images/stMarysBayGreen.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <ImageStyled fluid={data.placeholderImage.childImageSharp.fluid} />
        <Overlay />
      </Fragment>
    )}
  />
)

export default HeroImage
