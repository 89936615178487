import React, { Fragment } from 'react'

const faqs = [
  {
    heading: (
      <Fragment>
        Why should I franchise instead of starting my own business brokerage
        company?
      </Fragment>
    ),
    text: (
      <p>
        Franchising any kind of business gives business owners a significant
        advantage on the competition. Franchises come with systems and processes
        that have been tested and have proven to work. Transworld Business
        Advisors franchises also come with a strong supporting network and
        technology that can make it easier for franchisees to help clients.
      </p>
    ),
  },
  {
    heading: (
      <Fragment>
        What makes the Transworld Business Advisors opportunity special?
      </Fragment>
    ),
    text: (
      <p>
        Once you become a Transworld Business Broker franchisee you are able to
        build your business for yourself but not by yourself. We will encourage
        you to gain your Agent’s License after you have gained three years of
        experience and at that point you can hire your own brokers to truly
        leverage your business.
      </p>
    ),
  },
  {
    heading: (
      <Fragment>
        Do I have to need business brokerage or franchising experience to get
        started?
      </Fragment>
    ),
    text: (
      <p>
        In New Zealand, the REAA 2008 Act requires that business brokers have a
        real estate license, so additional training will be necessary. It is not
        necessary for franchisees to have industry experience, although some
        degree of business or real estate knowledge is a plus. At Transworld
        Business Advisors, you do receive two full weeks of training, as well as
        continuous support throughout the life of the business.
      </p>
    ),
  },
  {
    heading: <Fragment>How will I build my business?</Fragment>,
    text: (
      <p>
        Your franchise territory is based on a logical geographic territory
        with a minimum of 7000 employing businesses (a minimum of one staff member over and above the owner). Our
        proven prospecting systems, advertising campaigns and networking systems
        will ensure that if you bring the effort you can quickly build your
        business.
      </p>
    ),
  },
  {
    heading: <Fragment>How do I exit my franchise?</Fragment>,
    text: (
      <p>
        One of the benefits of buying a Business broker franchise is that you
        are the perfect person to sell your business once you reach the point
        you wish to exit. You will know how to appraise, market and close the
        sale. All we ask is that we have the right to approve the new owner
        before the transaction can be completed.
      </p>
    ),
  },
  {
    heading: <Fragment>Can I sell a business outside my territory?</Fragment>,
    text: (
      <p>
          You have an exclusive marketing territory which
          means you can only market your services within our territory,
          but should you have a contact or a family member outside your territory,
          you are able to take that business to the market.
      </p>
    ),
  },
  {
    heading: <Fragment>Is there Finance available?</Fragment>,
    text: (
      <p>
        Yes we have finance options available. We can go through this with you
        at our first meeting.
      </p>
    ),
  },
]

export default faqs
