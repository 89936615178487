import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import SEO from '../components/seo'
import Layout from '../components/Layout'
import OurProcess from '../components/molecules/content/OurProces'
import DarkFooter from '../components/molecules/footers/DarkFooter'
import PrimaryHeader from '../components/molecules/headers/PrimaryHeader'
import MaxWidthContainer from '../components/atoms/container/MaxWidthContainer'
import HeroImage from '../components/atoms/images/HeroImage'
import IconScroll from '../components/atoms/buttons/IconScroll'
import TeamMembers from '../components/molecules/content/TeamMembers'
import FixedMenu from '../components/molecules/headers/FixedMenu'
import WhyTWorld from '../components/molecules/content/WhyTWorld'
import AnimationSlideUp from '../components/atoms/content/AnimationSlideUp'
import Opportunity from '../components/molecules/content/Opportunity'
import WhatItCost from '../components/molecules/content/WhatItCost'
import WhatDoIGet from '../components/molecules/content/WhatDoIGet'
import Support from '../components/molecules/content/Support'
import FAQSection from '../components/molecules/faqsection/FAQSection'
import AboutUs from '../components/molecules/content/AboutUs'

const HeroGridStyled = styled(Grid)`
  && {
    position: relative;
    color: ${({ theme }) => theme.palette.white};
  }

  h1 {
    font-size: 81px;
    max-width: 70%;
    margin: 0 auto;
    text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  }

  p {
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0 25px 0;
    text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  }

  & .grid-item {
    z-index: 9;
    margin: 0 auto;
    max-width: 1200px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && h1 {
      font-size: 30px;
      max-width: 100%;
    }

    && p {
      font-size: 16px;
      line-height: 1.2;
    }
  }
`

const HeroContentContainer = styled(MaxWidthContainer)`
  && {
    z-index: 9;
    min-height: 100vh;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
`

const MenuTrigger = styled.div`
  height: 1px;
  width: 100%;
  display: block;
`

const IndexPage = () => {
  useMemo(() => {
    if (typeof window !== 'undefined' && window) {
      document.addEventListener(
        'click',
        e => {
          if (window.gtag) {
            window.gtag('event', 'click', { eventLabel: e.target.innerText })
          }
        },
        false
      )
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <HeroGridStyled container>
        <HeroImage />
        <PrimaryHeader position="absolute" />
        <FixedMenu />
        <AnimationSlideUp>
          <HeroContentContainer container justify="center">
            <Grid className="grid-item" item>
              <h1>Become a Transworld Franchise Owner</h1>
            </Grid>
            <Grid className="grid-item" item>
              <p>Join the World Leader in Business Brokerage</p>
            </Grid>
          </HeroContentContainer>
        </AnimationSlideUp>
        <IconScroll />
        <MenuTrigger id="menu-trigger" />
      </HeroGridStyled>

      <AboutUs />
      <WhyTWorld />
      <Opportunity />
      <WhatItCost />
      <WhatDoIGet />
      <Support />
      <OurProcess />
      <TeamMembers />
      <FAQSection />
      <DarkFooter />
    </Layout>
  )
}
export default IndexPage
