import styled from 'styled-components'
import React, { memo } from 'react'
import { Controller, Scene } from 'react-scrollmagic'

import PrimaryHeader from './PrimaryHeader'

const MenuStyled = styled.div`
  top: -100px;
  left: 0px;
  width: 100%;
  z-index: 99;
  height: 80px;
  display: none;
  display: block;
  position: fixed;
  background: #fff;
  transition: top 0.3s ease;
  box-shadow: 0 2px 4px 0 rgba(210, 210, 210, 0.5);

  &.reveal {
    top: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & {
      height: 80px;
    }
  }
`

const FixedMenu = memo(() => {
  return (
    <Controller>
      <Scene
        duration={100}
        pin={false}
        reverse
        indicators={false}
        offset={300}
        triggerElement="#menu-trigger">
        {progress => {
          return (
            <MenuStyled className={progress === 1 ? 'reveal' : null}>
              <PrimaryHeader position="relative" darkLogo />
            </MenuStyled>
          )
        }}
      </Scene>
    </Controller>
  )
})

export default FixedMenu
