import React from 'react'

const process = [
  {
    heading: `Complete the Request Information Form`,
    text: (
      <p>
        Please complete our Request Info form or give us a call. We will answer
        your initial questions and set up a meeting either in person or by
        Skype/Zoom.
      </p>
    ),
  },
  {
    heading: `Meet the Team`,
    text: (
      <p>
        This is a great opportunity for us to get to know each other. At
        Transworld Business Advisors, we specialize in building relationships,
        and that is no different when working with potential franchise owners.
        We will guide you every step of the way, including your
        grand opening and beyond!
      </p>
    ),
  },
  {
    heading: `Complete Your Personal Profile`,
    text: (
      <p>
        We want every franchisee to be successful when they join Transworld. For
        this reason, we ask that you fill out a brief background about yourself.
        This allows us to learn about you, your business background, questions
        and concerns, financial capabilities, etc.
      </p>
    ),
  },
  {
    heading: `Receive the Franchise Disclosure Document (FDD)`,
    text: (
      <p>
        As a member of the Franchise Association of New Zealand we must provide
        you with an approved Franchise Disclosure document and Franchise
        Agreement. These documents will provide you with better understanding of the opportunity.
      </p>
    ),
  },
  {
    heading: `Second meeting to answer questions`,
    text: (
      <p>
        We meet again to review and answer any questions that arise from
        reading through the Franchise documentation. At this point we will
        advise you to meet with a Lawyer to go through the Franchise
        agreement and an Accountant to help with your possible business setup
        and financial requirements. We will also pass on contact details for the
        Franchise friendly Banks.
      </p>
    ),
  },
  {
    heading: `Discovery Day`,
    text: (
      <p>
        Should you still be interested in proceeding at this point, we encourage you to attend a Discovery Day at Transworld Business
        Advisors Australia to see all aspects of Transworld and the parent
        company United franchise Group.
      </p>
    ),
  },
  {
    heading: `Sign Franchise Agreement`,
    text: <p>Congratulations you are now a Business Broker Franchise</p>,
  },
  {
    heading: `Attend Training School`,
    text: (
      <p>
        This is an exciting first step toward opening your Transworld franchise.
        While attending the 2-week world-class training in West Palm Beach,
        Florida, you will learn everything you need to know about starting and
        running your new business. Collaborate, learn, and grow with fellow
        franchisees and team members.
      </p>
    ),
  },
]

export default process
