import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const HeaderContainer = styled(Grid)`
  && {
    margin-bottom: 45px;
  }

  & h2 {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.1;
    color: rgb(0, 0, 0);
    margin: 0 0 20px 0;
  }

  & h4 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.lightGrey};
  }

  & h5 {
    font-size: 20px;
    margin-top: -8px;
    font-family: ${({ theme }) => theme.typography.fontFamilyRegular};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      text-align: left;
      margin-bottom: 35px;
    }

    & h2 {
      font-size: 25px;
    }

    & h5 {
      font-size: 16px;
    }
  }
`

const SectionHeader = ({ text, subText, postText, ...other }) => {
  return (
    <HeaderContainer item xs={12} {...other}>
      {subText && <h4>{subText}</h4>}
      <h2>{text}</h2>
      {postText && <h5>{postText}</h5>}
    </HeaderContainer>
  )
}

SectionHeader.propTypes = {
  subText: PropTypes.string,
  postText: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default SectionHeader
