import React, { Fragment } from 'react'
import * as INFO_LINKS from './infoLinks.json'

const InfoLinkContent = [
  {
    type: INFO_LINKS.ABOUTS_US,
    short: 'About Us',
    seo: 'About Us',
    description:
      'Transworld Business Advisors was established in 1979 by Don & Bonnie Parish in Florida in the United States',
    prev: null,
    next: INFO_LINKS.WHY_TRANSWORLD,
    text: (
      <React.Fragment>
        <p>
          Transworld Business Advisors was established in 1979 by Don & Bonnie
          Parish in Florida in the United States. Don had come from a successful
          30-year career as an IBM executive. <br />Andy Cagnetta, CEO & owner
          of Transworld Florida originally came to Transworld as a buyer looking
          for business opportunities from his first meeting; he knew Transworld
          was special and different. He was so impressed with the Transworld
          model he asked Don if he could work for the business. After a few
          years of working for Don, Andy bought Transworld and quickly grew it
          to the largest business brokerage in Florida.
          <br />
          <br />
          The company was thriving but Andy envisioned even more growth. It
          wasn’t long before United Franchise Group CEO Ray Titus and Andy
          Cagnetta teamed up to form a partnership and offer Transworld Business
          Advisors as a franchise opportunity.
          <br />
          <br />
          Ray Titus has over 30 years of franchise expertise having established
          the United Franchise Group which has developed or owns a number of
          award-winning franchises including Signarama, Transworld Business
          Advisors, Fully Promoted, Experimac, SuperGreen Solutions, VentureX,
          The Great Greek, Jon Smith Subs, and Network Lead Exchange and many
          others.
          <br />
          With Ray’s franchise success and Andy’s business brokerage industry
          experience, together they have grown Transworld into what it is today
          – one of The World’s Leading Business Brokerage Franchise – with more
          than 235 territories in 12 countries worldwide.
          <br />
          Transworld has arrived in New Zealand and is establishing a nationwide
          team of franchise brokers to operate their own franchise businesses in
          their local area.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: INFO_LINKS.WHY_TRANSWORLD,
    seo: 'Why Choose Transworld?',
    short: 'Why Choose Transworld?',
    description: `Franchising is a worldwide $4 trillion industry and continues to grow!`,
    prev: INFO_LINKS.ABOUTS_US,
    next: INFO_LINKS.THE_OPPORTUNITY,
    text: (
      <React.Fragment>
        <p>
          NZ has well and truly established itself as a nation that wants to own
          businesses. Kiwi’s love to be self-employed as a result we have the
          highest ownership of business in the world on a per capita basis!
          <br />
          <br />Franchising has played a big part in that.<br />
          <br />
          Why is that? Many Kiwis aspire to be self-employed, and because
          franchising works! It is a proven fact that franchising offers a new
          business owner a significantly higher chance for success than for
          non-franchise business start-up.
          <br />
          <br />
          Using Transworld's proven franchise systems/training combined with
          your drive and commitment and you have a winning formula to build your
          own successful business brokerage. Transworld will support and work
          alongside you using systems to identify prospective sellers and
          buyers. As part of that process Transworld will be actively involved
          in the transaction to maximize your success. We can also work with and
          offer our client services to access markets like Australia, USA and
          the UK.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: INFO_LINKS.THE_OPPORTUNITY,
    seo: 'The opportunity.  How do I make money?',
    short: 'How do I make money?',
    description: `A Transworld Business Brokerage Franchise provides for multiple revenue streams`,
    prev: INFO_LINKS.WHY_TRANSWORLD,
    next: INFO_LINKS.WHAT_IT_COST,
    text: [
      {
        heading: `General business sales`,
        text: (
          <React.Fragment>
            <p>
              A Business Broker operates in a professional capacity to help
              business owners sell, and sometimes assist buyers to buy or
              acquire privately held businesses.
            </p>
            <p>
              As a Business Broker you will use Transworld’s systems to appraise
              the business for sale, create marketing documentation, market the
              business, document the conditional sale and then run the Due
              Diligence process until the offer becomes unconditional.
            </p>
            <p>
              Your business clients can range from small owner operators through
              to Mid-Sized SMEs.
            </p>
          </React.Fragment>
        ),
      },
      {
        heading: `Franchise recruitment/sales`,
        text: (
          <React.Fragment>
            <p>
              Franchise recruitment allows you as a Broker to introduce your
              perspective buyers to a range franchise businesses. These can
              range from small green field local franchises through to
              International Master Franchise opportunities looking for New
              Zealand partners.
            </p>
            <p>
              As new options become available, you will have access to these as
              well.
            </p>
          </React.Fragment>
        ),
      },
      {
        heading: `Franchise Development`,
        text: (
          <React.Fragment>
            <p>
              Franchising is an area that many successful businesses are
              exploring as a strategy for growth. Franchises are becoming an
              increasingly popular throughout New Zealand and when you join
              Transworld you will work in conjunction with our Development team
              to help these entrepreneurs turn their business or concept into a
              franchise.
            </p>
            <p>
              If you have someone interested in franchising their business we
              can provide a ‘turnkey’ franchise system. We have successfully
              created franchise systems in all of the countries in which
              Transworld operates. All you need to do is successfully introduce
              a client and once the work is completed, you will be remunerated.
            </p>
          </React.Fragment>
        ),
      },
      {
        heading: `Business sales growth demand`,
        text: (
          <React.Fragment>
            <p>
              The business brokerage industry is a growth industry that has its
              best days ahead of it.
            </p>
            <p>
              During the last economic downturn (March 2009) the ASB released a
              survey that said that 25% of business owners polled wished to exit
              their business in the next 5-10 years. There are approximately
              475,000 businesses in New Zealand so that would mean around
              120,000 business owners at that time wanted to exit. So as these
              business owners age, especially the Baby Boomers, it is safe to
              assume that that 25% number will increase.
            </p>
            <p>
              There are approximately 400 active business brokers in New Zealand
              so you do the math’s on whether there are enough brokers to look
              after these prospective sales.
            </p>
          </React.Fragment>
        ),
      },
      {
        heading: `Meet a growing need`,
        text: (
          <Fragment>
            <p>
              The need for experienced business brokers is growing every year.
              Business owners are aging, and Business brokers will have to come
              up with some creative ways to complete transactions with the
              younger generations lack of assets and security going forward.
            </p>
            <p>
              It is very aspirational for Kiwi’s to want to achieve the dream of
              self-employment. Business brokers are the gateway to those
              business opportunities.
            </p>
          </Fragment>
        ),
      },
      {
        heading: `Growing Franchise Demand`,
        text: (
          <p>
            The franchise industry is booming, and many Kiwi’s with dreams of
            being self-employed are looking to buy a franchise business. With
            the growing demand for franchises, many business owners are looking
            to replicate their success through franchising. <br />Transworld
            Business Advisors, via our in-house partner Accurate Franchising,
            help business owners document and systemize their processes and
            model, so they can be replicated in the franchise world.
          </p>
        ),
      },
      {
        heading: `Help Business Owners Exit with Confidence`,
        text: (
          <p>
            One of the most challenging aspects of a business sale transaction
            is confidentiality. Most business owners do not want customers and
            employees to know that their business is for sale until after the
            transaction has been completed, so they can properly introduce the
            new owner. Employees can also begin searching for other jobs out of
            fear that the business will not remain stable. When customers and
            employees leave, it can affect revenues and stability. Transworld
            Business Advisors helps business owners sell in confidence.
            Transworld Business Advisors helps business owners to sell without
            ever having to put a "for sale" sign up or worry about how the sale
            will affect the way the business operates through the process.
          </p>
        ),
      },
    ],
  },
  {
    type: INFO_LINKS.WHAT_IT_COST,
    seo: 'OK, what does it cost?',
    short: 'What does it cost?',
    description: `Franchises are becoming an increasingly popular option as a fast track to business ownership throughout New Zealand`,
    prev: INFO_LINKS.THE_OPPORTUNITY,
    next: INFO_LINKS.WHAT_DO_I_GET,
    text: null,
  },
  {
    type: INFO_LINKS.WHAT_DO_I_GET,
    seo: 'What do I get?',
    short: 'What do I get?',
    description: `You will fly to Florida in the USA and be hosted for two weeks training at the Transworld headquarters`,
    prev: INFO_LINKS.WHAT_IT_COST,
    next: INFO_LINKS.SUPPORT,
    text: [
      {
        heading: `Initial Training`,
        text: (
          <p>
            You will fly to Florida in the USA and be hosted for two weeks
            training at the Transworld headquarters. The cost of flights and
            accommodation is included. You will meet other franchisees as well
            as all of Transworld franchise team.
          </p>
        ),
      },
      {
        heading: `Tablet Computer`,
        text: (
          <p>
            A wi-fi capable tablet computer comes loaded with the latest
            versions of Transworld training modules, forms, presentations, CRM,
            as well as a full Microsoft Office suite.{' '}
          </p>
        ),
      },
      {
        heading: `Sydney 3.0`,
        text: (
          <p>
            Sydney is a multi-faceted software that serves as a CRM to track all
            your contacts and listings, schedule appointments, manage tasks and
            maintain important records. This system will house your business and
            franchise listings as well as function as a full MLS (multiple listings service); powering the
            opportunities on your website.{' '}
          </p>
        ),
      },
      {
        heading: `Website`,
        text: (
          <p>
            Your own personalized website will showcase your contact information
            and business opportunities. It will provide all the necessary information
            a person would need to buy or sell a business. Your business will be
            prominently displayed on an attractive website that you can update
            or change all within the administration access.
          </p>
        ),
      },
      {
        heading: `Direct Mail Campaign`,
        text: (
          <p>
            A direct mail advertising campaign will help reaching local businesses
            within your territory to increase your exposure and help build your
            brand in the local community. These campaigns are often kept on file
            by business owners until they are ready to sell.
          </p>
        ),
      },
      {
        heading: `Drop Letter Campaign`,
        text: (
          <p>
            A supply of pre-printed drop letters and envelopes are provided to
            complete your initial canvassing campaign.
          </p>
        ),
      },
      {
        heading: `Corporate Print Supply Package`,
        text: (
          <p>
            All of your stationery requirements which includes business cards,
            personalized brochures and presentation folders are provided to help
            you start your business.
          </p>
        ),
      },
      {
        heading: `Apparel Package`,
        text: (
          <p>
            This includes an initial supply of high quality Transworld
            shirts of your choice. These embroidered shirts provide a
            professional lasting impression when meeting new clients.
          </p>
        ),
      },
      {
        heading: `Xero Software`,
        text: (
          <p>
            The Xero accounting software will be used to record monthly sales &
            expenses, handle payroll, and generate business reports.
          </p>
        ),
      },
      {
        heading: `On-Site Training`,
        text: (
          <p>
            Training will be provided in your territory upon your return from Florida, USA.
          </p>
        ),
      },
      {
        heading: `Great Work/Life Balance`,
        text: (
          <p>
            You have the option to create your own work schedule, and work from
            any location you desire. The business is easily operated using a
            laptop and mobile phone. Therefore, giving you flexibility in where,
            when, and how you work. You decide!
          </p>
        ),
      },
      {
        heading: `Support Network`,
        text: (
          <Fragment>
            <p>
              Local support and backed with international experience.
            </p>
          </Fragment>
        ),
      },
    ],
  },
  {
    type: INFO_LINKS.SUPPORT,
    seo: 'Training/Ongoing Support',
    short: 'Training/Ongoing Support',
    prev: INFO_LINKS.WHAT_DO_I_GET,
    description: `Hands on support to assist you on your journey`,
    next: null,
    text: [
      {
        heading: `Initial Training`,
        image: require('../../src/assets/images/landing/support/training.jpg'),
        text: (
          <Fragment>
            <p>
              All new Transworld franchisees attend training for two weeks at
              United Franchise Group’s headquarters in sunny West Palm Beach,
              Florida. You will learn all aspects of the Transworld system
              including things like prospecting, marketing, negotiation,
              contracts and much more.
            </p>
            <p>
              We will also cover the 3 service/revenue pillars of the Transworld
              Business Advisors franchise: Business broking, Franchise
              consulting, and Franchise Development.
            </p>
            <p>
              The training is extensive because we assume that our new
              franchisees have little industry experience and have never run a
              business. These systems will show you how you will build your own
              successful business in your local area
            </p>
          </Fragment>
        ),
      },
      {
        heading: `Your Start-Up`,
        image: require('../../src/assets/images/landing/support/start-up.jpg'),
        text: (
          <p>
            After you return from your training in Florida, and upon obtaining
            your Real Estate Salespersons License, we will assist you to implement what you have learned and put it into practice in your local territory.
            Having an experienced industry mentor in the early stages of
            building your business means you feel more comfortable and confident
            while gaining hands on experience in front of your clients.
          </p>
        ),
      },
      {
        heading: `Your Initial Marketing`,
        image: require('../../src/assets/images/landing/support/marketing.jpg'),
        text: (
          <Fragment>
            <p>
              Your initial marketing is split into two categories: Proactive
              strategies which you will learn during your initial training and
              Passive strategies which include Direct Mail, LinkedIn marketing
              and Google AdWords. These strategies allow you to build awareness
              of you as a broker while also building brand awareness in your
              local area.
            </p>
          </Fragment>
        ),
      },
      {
        heading: `Your Ongoing Support`,
        image: require('../../src/assets/images/landing/support/support.jpg'),
        text: (
          <Fragment>
            <p>
              As business brokers we are legislated under the REAA 2008 ACT.
              Under the ACT a Licensed Agent who is operating a business must
              ensure that all salespersons engaged by the agent are properly
              supervised and managed. What does this mean for you? This means
              you will receive ongoing support for all aspects of the business
              relating to the transactional practices of the business as
              required under the ACT.
            </p>
          </Fragment>
        ),
      },
    ],
  },
]

export default InfoLinkContent
