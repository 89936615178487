import React, { useRef, useCallback } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { string } from 'prop-types'
import { Grid, Hidden } from '@material-ui/core'
import styled, { css } from 'styled-components'
import Slider from 'react-slick-adaptive-height-fixed'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'

import SectionHeader from '../../atoms/content/SectionHeader'
import InfoLinkContent from '../../../data/InfoLinkContent'
import * as INFO_LINKS from '../../../data/infoLinks.json'
import PaddedContent from '../../atoms/container/PaddedContent'
import DirectionalButton from '../../atoms/buttons/DirectionalButton'
import workLifeBalance from '../../../assets/images/landing/whatdoiget.jpeg'
import MobileSectionSeperateImage from '../../atoms/misc/MobileSectionSeperateImage'

const WHAT_DO_I_GET = InfoLinkContent.find(
  item => item.type === INFO_LINKS.WHAT_DO_I_GET
)

const ContentContainer = styled(Grid)`
  && {
    ${PaddedContent};
    padding-right: 0;
    background-image: linear-gradient(to top, #f9f9f9, #ffffff);
  }

  p {
    margin: 0;
    font-size: 20px;
    line-height: 1.5;
  }

  .image-container {
    background-size: cover;
    background-position: center;
    background-image: url(${workLifeBalance});
  }

  .text-container {
    position: relative;
    padding-right: 35px;
    padding: 120px 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    p {
      font-size: 18px;
    }

    .text-container {
      padding: 20px 0;
    }

    .image-container {
      ${MobileSectionSeperateImage};
      margin-top: -15px;
    }
  }
`

const buttonStyle = css`
  top: 45%;
  position: absolute;
  transform: translateY(-50%);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: 200px;
  }
`

const NextButton = styled(DirectionalButton)`
  && {
    right: 15px;
    ${buttonStyle};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    && {
      right: 0;
    }
  }
`
const BackButton = styled(DirectionalButton).attrs({ flip: true })`
  && {
    left: 0;
    ${buttonStyle};
  }
`

const SliderStyled = styled(Slider)`
  .item {
    padding: 30px 50px;
    text-align: center;
  }

  .item h2 {
    font-size: 30px;
    margin: 0 0 45px 0;
    position: relative;
  }

  .item h2 > span {
    left: 50%;
    top: -34px;
    width: 100%;
    opacity: 0.2;
    font-size: 65px;
    line-height: 95px;
    position: absolute;
    color: ${({ theme }) => theme.palette.primary.brandGold};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .item h3 {
    display: none;
    opacity: 0.2;
    line-height: 1;
    font-size: 50px;
    margin: 0 0 15px 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .item h2 > span {
      display: none;
    }
    .item h3 {
      display: block;
    }
  }

  && .slick-active .whatIGet_section {
    opacity: 1;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .item h2 > span {
      left: 60%;
      top: -50px;
      font-size: 55px;
    }
  }
`

const SectionHeaderStyled = styled(SectionHeader)`
  text-align: center;
`

const WhatDoIGet = ({ width }) => {
  const sliderRef = useRef()
  const sliderSettings = {
    speed: 500,
    swipe: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    dots: isWidthUp('sm', width),
    adaptiveHeight: isWidthDown('sm', width),
  }

  const handleNextPress = useCallback(() => {
    sliderRef.current.slickNext()
  }, [])

  const handleBackPress = useCallback(() => {
    sliderRef.current.slickPrev()
  }, [])

  return (
    <ContentContainer container>
      <SectionHeaderStyled
        text={WHAT_DO_I_GET.short}
        postText="Here are some of the benefits of choosing Transworld"
      />
      <Hidden smUp>
        <Grid className="image-container" item md={5} />
      </Hidden>
      <Grid className="text-container" item xs={12} md={7}>
        <SliderStyled ref={sliderRef} {...sliderSettings}>
          {WHAT_DO_I_GET.text.map((item, index) => (
            <div className="item" key={index}>
              <h3>{index + 1}</h3>
              <h2>
                {item.heading}
                <span>{index + 1}</span>
              </h2>
              {item.text}
            </div>
          ))}
        </SliderStyled>
        <BackButton onClick={handleBackPress} />
        <NextButton onClick={handleNextPress} />
      </Grid>
      <Hidden xsDown>
        <Grid className="image-container" item md={5} />
      </Hidden>
    </ContentContainer>
  )
}

WhatDoIGet.propTypes = {
  width: string.isRequired,
}

export default withWidth()(WhatDoIGet)
