import React, { Fragment } from 'react'
import David from '../components/atoms/images/David'
import Andrew from '../components/atoms/images/Andrew'
import Bill from '../components/atoms/images/Bill'
import Mark from '../components/atoms/images/Mark'

const OurTeamData = [
  {
    isPrimary: true,
    image: <David />,
    name: 'David Newport',
    title: 'Director of Transworld Business Advisors New Zealand, Licensed REAA 2008',
    text: (
      <Fragment>
        <p>
          Dave started in business sales in the mid 1990s and focused on selling
          franchises throughout NZ and also providing franchisee training and
          support. The natural progression was to move into the general business
          market selling where he focused on Medium to Medium-Large SMEs
          primarily in the Auckland market.
        </p>
        <p>
          In 2009 he established Switch Business Ltd, which has traded
          successfully selling both General and Franchise businesses. While
          recovering from a serious accident in 2017 David decided he wanted to
          get involved in a business which offered him the opportunity to create
          a team and help others make sales, and this was the main driver which
          lead to the purchase of Transworld Business Advisors for NZ market.
        </p>
      </Fragment>
    ),
  },
  {
    title: 'Franchise Recruitment Director',
    name: 'Mark Lewis',
    isPrimary: true,
    image: <Mark />,
    text: (
        <Fragment>
          <p>
              Mark has near on 30 years of experience in the Franchising industry both in New Zealand & Australia. He has recruited over 300+ franchisees and has experience in the development of franchise systems, recruitment, operations and hands on experience as a franchisor securing the NZ rights for Hire a Hubby, building the business and selling to Fletcher Building.
          </p><p>
              He has gained extensive experience in most facets of the Motor Vehicle industry over the last 20 years-importing, distribution, wholesaling, retailing, finance and service centres.
        </p><p>
              Specialties: Sales, Negotiations, Franchise Development & Sales, Management, Importing, Private Equity
          </p>
        </Fragment>
    ),
  },
  {
    name: 'Andrew Cagnetta',
    image: <Andrew />,
    title: 'CEO and Owner of Transworld Business Advisors, Florida',
    text: (
      <p>
        Andrew Cagnetta delights in helping other entrepreneurs achieve their
        goals. Andrew has been in the business brokerage industry for over 20
        years. In addition to his business endeavors, Andrew has also helped to
        fund and break ground on a Junior Achievement USA location, and is an
        organizer and cook for Andy’s Family Pasta Dinner, an annual fundraiser
        geared toward helping feed families in Broward County, Florida.
      </p>
    ),
  },
  {
    name: 'Bill Luce',
    image: <Bill />,
    title: 'President of Transworld Business Advisors',
    text: (
      <p>
        Bill Luce, President of Transworld Business Advisors has more than 20
        years of experience in global sales and executive management. Bill
        joined United Franchise Group in 1994 as the Director of Franchise
        Development. During his time in this role he designed and developed a
        franchise sales system that included all aspects of advertising, lead
        generation, software implementation, collateral material, initial
        prospect contact and management with Regional Vice Presidents. He
        continues to strengthen the foundation of the company and ensure the
        success of Transworld Business Advisors worldwide. In an industry where
        businesses are put up for sale every day and franchising is experiencing
        explosive growth, the Transworld Business Advisors model is poised for
        international success.
      </p>
    ),
  },
]

export default OurTeamData
