import React from 'react'
import styled from 'styled-components'

import TeamMemberIntro from './TeamMemberIntro'
import OurTeamData from '../../../data/our-team'
import SectionHeader from '../../atoms/content/SectionHeader'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'
import AnimationSlideUp from '../../atoms/content/AnimationSlideUp'
import PaddedContent from '../../atoms/container/PaddedContent'

const IntroStyled = styled(MaxWidthContainer)`
  ${PaddedContent};
  p {
    font-size: 18px;
    line-height: 1.5;
  }
`

const AnimationSlideUpStyled = styled(AnimationSlideUp)`
  background: linear-gradient(to top, #f9f9f9, #ffffff);
`

const TeamMembers = () => (
  <AnimationSlideUpStyled>
    <IntroStyled id="our-team" container>
      <SectionHeader text="Meet the team" postText="Your support network" />
      {OurTeamData.map((item, index) => (
        <TeamMemberIntro key={index} member={item} />
      ))}
    </IntroStyled>
  </AnimationSlideUpStyled>
)

export default TeamMembers
