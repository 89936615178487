import { string } from 'prop-types'
import { Grid, Tabs, Tab } from '@material-ui/core'
import Slider from 'react-slick-adaptive-height-fixed'
import React, { useRef, useMemo, useCallback } from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import styled from 'styled-components'
import SectionHeader from '../../atoms/content/SectionHeader'
import InfoLinkContent from '../../../data/InfoLinkContent'
import * as INFO_LINKS from '../../../data/infoLinks.json'
import BorderBottom from '../../atoms/misc/BorderBottom'
import PaddedContent from '../../atoms/container/PaddedContent'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'

const SUPPORT = InfoLinkContent.find(item => item.type === INFO_LINKS.SUPPORT)

const ContentContainer = styled(MaxWidthContainer)`
  && {
    ${PaddedContent};
    ${BorderBottom};
    font-size: 20px;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }

  .image-container {
    background-size: cover;
    background-position: center;
  }

  .text-container {
    padding-left: 35px;
  }

  .MuiTabs-scroller {
    overflow: hidden;
    margin-bottom: 35px;
    border-bottom: 1px solid #eee;
  }

  && .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.primary.brandGold};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .text-container {
      padding-left: 0px;
    }
  }
`

const SectionHeaderStyled = styled(SectionHeader)`
  text-align: center;
`

const TextContentStyled = styled(Grid)`
  padding: 35px;

  h2 {
    margin: 0 0 15px 0;
    text-transform: uppercase;
  }

  p {
    opacity: 0.5;
    font-size: 18px;
  }

  p:first-of-type {
    opacity: 1;
    font-size: 20px;
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 0 35px 0;

    && p {
      opacity: 1;
      font-size: 18px;
    }

    h2 {
      font-size: 22px;
      text-transform: none;
      color: ${({ theme }) => theme.palette.brandGold};
    }
  }
`

const SliderStyled = styled(Slider)`
  .item {
    padding: 30px 50px;
    text-align: center;
  }

  .item h2 {
    font-size: 30px;
    margin: 0 0 45px 0;
    position: relative;
  }

  .item h2 > span {
    left: 0;
    top: -34px;
    width: 100%;
    opacity: 0.2;
    font-size: 100px;
    line-height: 95px;
    position: absolute;
    color: ${({ theme }) => theme.palette.primary.brandGold};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  && .slick-active .whatIGet_section {
    opacity: 1;
  }
`

const Support = ({ width }) => {
  const sliderRef = useRef()
  const sliderSettings = useMemo(
    () => ({
      speed: 500,
      swipe: true,
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
    }),
    []
  )

  const [value, setValue] = React.useState(0)

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue)
    setTimeout(() => {
      sliderRef.current.slickGoTo(newValue)
    }, 250)
  }, [])

  const handleChangeIndex = useCallback(index => {
    setValue(index)
  }, [])

  return (
    <ContentContainer>
      <SectionHeaderStyled
        text={SUPPORT.short}
        postText={SUPPORT.description}
      />
      <Grid className="text-container" item md={12}>
        {isWidthUp('sm', width) && (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            {SUPPORT.text.map((item, index) => (
              <Tab key={index} label={item.heading} />
            ))}
          </Tabs>
        )}
        {isWidthUp('sm', width) ? (
          <SliderStyled
            afterChange={handleChangeIndex}
            ref={sliderRef}
            {...sliderSettings}>
            {SUPPORT.text.map((item, index) => (
              <div key={index}>
                <Grid container>
                  <Grid
                    item
                    className="image-container"
                    md={5}
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  />
                  <TextContentStyled item md={7}>
                    <h2>{item.heading}</h2>
                    {item.text}
                  </TextContentStyled>
                </Grid>
              </div>
            ))}
          </SliderStyled>
        ) : (
          <div>
            {SUPPORT.text.map((item, index) => (
              <div key={index}>
                <Grid container>
                  <Grid
                    item
                    className="image-container"
                    md={5}
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  />
                  <TextContentStyled item md={7}>
                    <h2>{item.heading}</h2>
                    {item.text}
                  </TextContentStyled>
                </Grid>
              </div>
            ))}
          </div>
        )}
      </Grid>
    </ContentContainer>
  )
}

Support.propTypes = {
  width: string.isRequired,
}

export default withWidth()(Support)
