import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const ImageStyled = styled(Img)`
  && {
  }
`
const LogoLight = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "common/logo-light.png" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <ImageStyled fluid={data.placeholderImage.childImageSharp.fluid} />
    )}
  />
)

export default LogoLight
