import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Drawer, Grid } from '@material-ui/core'

const DrawerStyled = styled(Drawer)`
  && > div:nth-of-type(3) {
    border-radius: 0px 0px 26px 26px;
  }
`
const MobileLinkContainer = styled(Grid)`
  && {
    min-height: 100px;
    padding: 15px 15px 20px 15px;
    background: ${({ theme }) => theme.palette.white};
  }

  && a {
    font-size: 20px;
    margin-top: 16px;
    line-height: 40px;
    letter-spacing: -0.25px;
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: ${({ theme }) => theme.typography.fontFamilySemiBold};
  }
`

const DropDownMenu = ({ isOpen, hideDropDown, children }) => {
  return (
    <DrawerStyled anchor="top" open={isOpen} onClose={hideDropDown}>
      <MobileLinkContainer direction="column" container>
        {children}
      </MobileLinkContainer>
    </DrawerStyled>
  )
}

DropDownMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  hideDropDown: PropTypes.func.isRequired,
}

export default DropDownMenu
