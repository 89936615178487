import React, { useState, Fragment, useEffect, useRef } from 'react'
import styled from 'styled-components'
import SectionHeader from '../../atoms/content/SectionHeader'
import InfoLinkContent from '../../../data/InfoLinkContent'
import * as INFO_LINKS from '../../../data/infoLinks.json'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'
import {
  Grid,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import AnimationSlideUp from '../../atoms/content/AnimationSlideUp'
import ExpansionPanel from '../../atoms/container/ExpansionPanel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import opportunity from '../../../assets/images/landing/opportunity.jpeg'
import PaddedContent from '../../atoms/container/PaddedContent'

const THE_OPPORTUNITY = InfoLinkContent.find(
  item => item.type === INFO_LINKS.THE_OPPORTUNITY
)

const ContentContainer = styled.div`
  ${PaddedContent};
  background: linear-gradient(to top, #eee, #ffffff);

  p {
    margin: 0;
    font-size: 18px;
    line-height: 1.5;
  }

  .image-container {
    background: url(${opportunity});
    background-size: cover;
    background-position: center;
  }

  .text-container {
    padding-left: 35px;
  }

  && .MuiExpansionPanel-rounded:last-child {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .text-container {
      padding: 0;
    }
  }
`

const Opportunity = () => {
  const containerRef = useRef(null)
  const [containerHeight, setContainerHeight] = useState(0)
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    setContainerHeight(containerRef.current.clientHeight)
  }, [])

  return (
    <ContentContainer>
      <MaxWidthContainer container>
        <Grid
          style={{
            maxHeight: containerHeight,
          }}
          className="image-container"
          item
          md={5}
        />
        <Grid ref={containerRef} className="text-container" item md={7}>
          <AnimationSlideUp>
            <SectionHeader
              text={THE_OPPORTUNITY.short}
              postText={THE_OPPORTUNITY.description}
            />
            {THE_OPPORTUNITY.text.map((item, index) => (
              <ExpansionPanel
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}d-content`}
                  id={`panel${index}d-header`}>
                  <h1>
                    <span className="item-number">0{index + 1}</span>
                    {item.heading}
                  </h1>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>{item.text}</ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </AnimationSlideUp>
        </Grid>
      </MaxWidthContainer>
    </ContentContainer>
  )
}

export default Opportunity
