import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Observer from 'react-intersection-observer'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

const OFFSET = '50px'
const SPEED = '0.75s'
const EASING = 'ease-out'

const ContainerStyled = styled.div(
  ({ 'data-delay': delay }) => `
    width: 100%;

    @supports (transform: translate3d(0, 0, 0)) {
      transition: all ${SPEED} ${EASING} ${delay}ms;
      transform: translate3d(0, ${OFFSET}, 0);
      opacity: 0;

      &.isInView {
        transform: none;
        opacity: 1;
      }
    }
  `
)

const AnimationSlideUp = ({
  children,
  className,
  triggerOnce,
  delay,
  ...other
}) => {
  return (
    <Observer triggerOnce={triggerOnce}>
      {({ inView, ref }) => (
        <ContainerStyled
          ref={ref}
          data-delay={delay}
          className={inView ? `isInView ${className}` : className}
          {...other}>
          {children}
        </ContainerStyled>
      )}
    </Observer>
  )
}

AnimationSlideUp.defaultProps = {
  delay: 0,
  triggerOnce: true,
}

AnimationSlideUp.propTypes = {
  delay: PropTypes.number,
  style: PropTypes.object,
  triggerOnce: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default AnimationSlideUp
