import { css } from 'styled-components'

const PaddedContent = css`
  padding: 100px 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 50px 20px;
  }
`

export default PaddedContent
