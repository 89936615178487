import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const ContentContainerStyled = styled(Grid)`
  && {
    margin-bottom: 75px;
  }
`

const TextContentStyled = styled(Grid)`
  padding: 0 25px 25px 25px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
  }
`

const SubHeaderTextStyled = styled.h4`
  margin: 0;
  opacity: 0.8;
  font-size: 18px;
  line-height: 1.45;
  text-transform: none;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`

const HeaderTextStyled = styled.h2`
  font-size: 32px;
  line-height: 1.1;
  font-weight: 700;
  margin: 25px 0 5px 0;
  text-transform: none;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 26px;
    text-align: center;
  }
`

const PictureStyled = styled.div`
  div:first-child {
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.palette.brandGold};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      max-width: 250px;
    }
  }
`

const TeamMemberIntro = ({ member }) => {
  return (
    <ContentContainerStyled container item md={6}>
      <Grid item xs={12} md={3}>
        <PictureStyled>{member.image}</PictureStyled>
      </Grid>
      <TextContentStyled item xs={12} md={9}>
        <HeaderTextStyled>{member.name}</HeaderTextStyled>
        <SubHeaderTextStyled>{member.title}</SubHeaderTextStyled>
        <div>{member.text}</div>
      </TextContentStyled>
    </ContentContainerStyled>
  )
}

TeamMemberIntro.propTypes = {
  member: PropTypes.shape({
    image: PropTypes.element,
    name: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default TeamMemberIntro
