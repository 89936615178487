import React from 'react'
import styled from 'styled-components'
import { Grid, Hidden } from '@material-ui/core'

import InfoLinkContent from '../../../data/InfoLinkContent'
import * as INFO_LINKS from '../../../data/infoLinks.json'
import BorderBottom from '../../atoms/misc/BorderBottom'
import SectionHeader from '../../atoms/content/SectionHeader'
import PaddedContent from '../../atoms/container/PaddedContent'
import AnimationSlideUp from '../../atoms/content/AnimationSlideUp'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'
import { Avatar, Umbrella, BreifCase, Help } from '../../atoms/images/SVGIcons'
import MobileSectionSeperateImage from '../../atoms/misc/MobileSectionSeperateImage'

const ABOUTS_US = InfoLinkContent.find(
  item => item.type === INFO_LINKS.ABOUTS_US
)

const AboutUsContainerStyled = styled(MaxWidthContainer)`
  ${PaddedContent};
  ${BorderBottom};

  p {
    font-size: 18px;
    line-height: 1.5;
  }

  .image-container {
    background-size: cover;
    background-position: left center;
    background-image: url(https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&auto=format&fit=crop&w=434&q=50);
  }

  .text-container {
    padding-left: 35px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .text-container {
      padding-left: 0;
    }

    .image-container {
      ${MobileSectionSeperateImage};
    }
  }
`

const AboutSectionSpecials = styled(Grid)`
  padding: 20px;
  margin: 35px 0 0;
  border-radius: 8px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px;

  svg {
    max-width: 35px;
    border-radius: 50%;
    transition: all 0.5s ease;
  }

  && .MuiGrid-item:hover svg {
    padding: 8px;
    transform: scale(1.5);
    fill: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) => theme.palette.lightGreen};
  }

  && .MuiGrid-item:hover p {
    color: ${({ theme }) => theme.palette.lightGreen};
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  .MuiGrid-item {
    border-right: 1px solid #eee;
  }

  .MuiGrid-item:last-child {
    border-right: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-bottom: 10px;

    .MuiGrid-item {
      border: none;
      margin-bottom: 10px;
    }
    p {
      margin-top: 5px;
      font-size: 12px;
    }
  }
`

const AboutUs = () => {
  return (
    <AboutUsContainerStyled container id="services">
      <Hidden xsDown>
        <Grid className="image-container" item md={4} />
      </Hidden>
      <Grid className="text-container" item md={8}>
        <AnimationSlideUp>
          <SectionHeader
            text={ABOUTS_US.short}
            postText="More than 235 territories in 12 countries worldwide"
          />
          <div>{ABOUTS_US.text}</div>
          <AboutSectionSpecials container>
            <Grid item md={3} xs={6}>
              <Avatar />
              <p>Experienced</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <Umbrella />
              <p>Trusted</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <BreifCase />
              <p>Professional</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <Help />
              <p>Reliable</p>
            </Grid>
          </AboutSectionSpecials>
        </AnimationSlideUp>
      </Grid>
      <Grid className="image-container" item md={4} />
    </AboutUsContainerStyled>
  )
}

export default AboutUs
