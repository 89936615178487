import React, { useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Grid,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'

import faqs from '../../../data/faq'
import SectionHeader from '../../atoms/content/SectionHeader'
import ExpansionPanel from '../../atoms/container/ExpansionPanel'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'
import PaddedContent from '../../atoms/container/PaddedContent'

const FAQSectionComponent = styled(Grid)`
  ${PaddedContent};
  background: linear-gradient(to top, #f9f9f9, #ffffff);
`

const CenteredSectionHeader = styled(SectionHeader)`
  text-align: center;
`

const ExpansionPanelStyled = styled(ExpansionPanel)`
  &&.MuiExpansionPanel-root {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px;
  }
`

const FAQSection = () => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <FAQSectionComponent container>
      <MaxWidthContainer>
        <CenteredSectionHeader
          text="Any Questions?"
          postText="It’s natural to have a lot of questions at this point in your decision-making process"
        />
        {faqs.map((item, index) => (
          <ExpansionPanelStyled
            square
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}>
              <h1>{item.heading}</h1>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{item.text}</ExpansionPanelDetails>
          </ExpansionPanelStyled>
        ))}
      </MaxWidthContainer>
    </FAQSectionComponent>
  )
}

export default FAQSection
