import styled from 'styled-components'

const IconScroll = styled.div`
  &&,
  &&:before {
    position: absolute;
    left: 50%;
  }
  && {
    width: 28px;
    height: 44px;
    bottom: 50px;
    margin-top: -35px;
    margin-left: -20px;
    border-radius: 25px;
    box-shadow: inset 0 0 0 2px #fff;
  }
  &&:before {
    content: '';
    width: 8px;
    height: 8px;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
    background-color: ${({ theme }) => theme.palette.white};
  }
  @-webkit-keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
  }
  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
  }
`

export default IconScroll
