import styled from 'styled-components'
import { ExpansionPanel as MUIExpansionPanel } from '@material-ui/core'

const ExpansionPanel = styled(MUIExpansionPanel)`
  && {
    box-shadow: none;
    font-size: 16px;
    line-height: 24px;
    border-bottom: none;
    letter-spacing: -0.2px;
    padding: 0 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: box-shadow ease 0.25s;
    color: ${({ theme }) => theme.palette.primary.dark};
    background-color: ${({ theme }) => theme.palette.white};
    font-family: ${({ theme }) => theme.typography.fontFamily}
    box-shadow: ${({ shadow }) => shadow && 'rgba(0, 0, 0, 0.1) 0px 20px 40px'};
  }

  &&&:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px;
  }

  &&:hover h1 {
    color: ${({ theme }) => theme.palette.brandGold};
  }

  .MuiExpansionPanelSummary-root {
    transition: all ease 0.25s;
  }

  && .item-number {
    font-size: 20px;
    padding: 0;
    margin-right: 12px;
    display: inline-block;
    vertical-align: top;
    color: ${({ theme }) => theme.palette.brandGold};
  }

  &&.Mui-expanded,
  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 6px 0 12px 0;
  }

  && .MuiExpansionPanelDetails-root {
    flex-direction: column;
  }

  && * {
    padding: 0;
  }

  && [role='button'] {
    margin: 0;
  }

  && [role='region'] {
    padding-bottom: 15px;
  }

  && [role='region'] p:first-child {
    margin-top: 0;
  }

  && [role='region'] p:last-child {
    margin-bottom: 0;
  }

  &&:before {
    background: transparent;
  }

  a {
    text-decoration: underline;
  }

  h1 {
    margin: 13px 0;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.25px;
    transition: color 0.25s ease;
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.typography.fontFamilySemiBold};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    h1 {
      font-size: 18px;
    }
  }

  ul {
    margin: 15px 15px 0px 15px;
  }

  svg {
    fill: ${({ theme }) => theme.palette.black};
  }

  p {
    font-size: 18px;
    color: ${({ theme }) => theme.palette.black};
  }
`

export default ExpansionPanel
