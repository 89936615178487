import styled from 'styled-components'
import { Tween, Timeline } from 'react-gsap'
import { Grid } from '@material-ui/core'
import { Controller, Scene } from 'react-scrollmagic'
import React, { useRef, useEffect, useState } from 'react'

import process from '../../../data/process'
import SectionHeader from '../../atoms/content/SectionHeader'
import PaddedContent from '../../atoms/container/PaddedContent'
import AnimationSlideUp from '../../atoms/content/AnimationSlideUp'

const GridStyled = styled(Grid)`
  ${PaddedContent};
  position: relative;
  padding-bottom: 25em;
  background: linear-gradient(
    #19231a 10%,
    #283048 60%,
    #55679a 80%,
    rgba(255, 255, 255, 0)
  );

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .right {
    float: right;
  }

  .svg-timeline {
    position: absolute;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: none;
  }
  @media screen and (min-width: 50em) {
    .svg-timeline {
      display: block;
    }
  }

  .timeline {
    transition: all 250ms ease;
    width: 90%;
    margin: 0 auto;
  }
  @media screen and (min-width: 31.25em) {
    .timeline {
      width: 80%;
    }
  }
  @media screen and (min-width: 50em) {
    .timeline {
      width: 95%;
    }
  }
  .timeline__item {
    margin-bottom: 4em;
    clear: both;
  }
  @media screen and (min-width: 50em) {
    .timeline__item {
      width: 40%;
    }
  }
  .timeline__day {
    font-weight: 600;
    font-size: 0.9em;
    color: white;
    text-transform: uppercase;
    padding-bottom: 0.75em;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }

  .clearfix {
    display: inline-block;
  }

  /* start commented backslash hack \*/
  * html .clearfix {
    height: 1%;
  }

  .clearfix {
    display: block;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    background: linear-gradient(
      #19231a 10%,
      #283048 60%,
      #55679a 87%,
      rgba(255, 255, 255, 0)
    );
    padding-bottom: 200px;

    .timeline {
      padding: 0;
      width: unset;
      max-width: unset;
    }

    && p {
      font-size: 18px;
    }
  }
`

const SectionHeaderStyled = styled(SectionHeader)`
  text-align: center;

  h2,
  h5 {
    color: ${({ theme }) => theme.palette.white};
  }
`

const TimelineDay = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.brandGold};

  span {
    display: block;
    font-size: 60px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 20px;
    text-align: center;

    span {
      font-size: 40px;
    }
  }
`

const TimeLineText = styled.div`
  p {
    color: white;
    font-size: 20px;
    line-height: 1.5;
    margin: 15px 0 0 0;
  }
`

const ListStyled = styled.ol`
  margin: 0 auto;
  list-style: none;
  max-width: 1200px;
  position: relative;
`

const TimelineImage = styled.div`
  display: block;
  background-image: url('https://images.unsplash.com/photo-1504064860048-974c8788c612?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3736&q=80');
  height: 500px;
  width: 100%;
  background-size: cover;
  position: absolute;
  background-position: 25% center;
  z-index: -10;
  bottom: 0;
  left: 0;
`

const TimelineWrapper = styled.main`
  margin: 0 auto;
  position: relative;
`

const OurProcess = () => {
  const containerRef = useRef(null)
  const [timeLineContainerHeight, setTimeLineContainerHeight] = useState(0)

  useEffect(() => {
    setTimeLineContainerHeight(containerRef.current.clientHeight)
  }, [])

  return (
    <GridStyled container id="our-process">
      <AnimationSlideUp>
        <SectionHeaderStyled
          text="Our Process"
          postText="How you become a Transworld Franchisee"
        />
        <TimelineWrapper className="timeline-wrapper" ref={containerRef}>
          <div className="svg-timeline">
            <Controller>
              <Scene
                duration={timeLineContainerHeight + 600}
                reverse
                offset={300}
                triggerElement="#our-process">
                {progress => {
                  return (
                    <Timeline paused totalProgress={progress}>
                      <Tween
                        wrapper={
                          <svg
                            width="8px"
                            height={`${timeLineContainerHeight}px`}
                            viewBox={`0 0 8 ${timeLineContainerHeight}`}
                          />
                        }
                        from={{
                          svgDraw: 0,
                        }}
                        to={{
                          svgDraw: 1,
                        }}>
                        <line
                          id="timeline"
                          fill="none"
                          stroke="#FFFFFF"
                          strokeWidth="8"
                          strokeMiterlimit="10"
                          x1="4"
                          y1="0"
                          x2="4"
                          y2={timeLineContainerHeight}
                        />
                      </Tween>
                    </Timeline>
                  )
                }}
              </Scene>
            </Controller>
          </div>
          <ListStyled className="timeline clearfix">
            {process.map((item, index) => (
              <li
                key={index}
                className={`timeline__item ${index % 2 === 1 && 'right'}`}>
                <TimelineDay>
                  <span>0{index + 1}</span>
                  {item.heading}
                </TimelineDay>
                <TimeLineText>{item.text}</TimeLineText>
              </li>
            ))}
          </ListStyled>
        </TimelineWrapper>
        <TimelineImage />
      </AnimationSlideUp>
    </GridStyled>
  )
}

export default OurProcess
