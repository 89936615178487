import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

const ImageStyled = styled(Img)`
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px;
`

const David = props => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "landing/david-newport.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <ImageStyled
        {...props}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
)

export default David
