import React from 'react'
import styled, { css } from 'styled-components'
import { Grid } from '@material-ui/core'
import {
  LowCost,
  Balance,
  WorldSupport,
  Profit,
  Experience,
  WorldWide,
} from '../../atoms/images/SVGIcons'
import InfoLinkContent from '../../../data/InfoLinkContent'
import * as INFO_LINKS from '../../../data/infoLinks.json'
import BorderBottom from '../../atoms/misc/BorderBottom'
import SectionHeader from '../../atoms/content/SectionHeader'
import PaddedContent from '../../atoms/container/PaddedContent'
import AnimationSlideUp from '../../atoms/content/AnimationSlideUp'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'

const StyledGrid = styled(Grid)`
  text-align: center;

  & h3,
  & p {
    margin: 0;
  }

  & h3 {
    margin: 15px;
  }
`

const GridItemStyled = styled(Grid)`
  && {
    margin: 0 auto 35px auto;
  }

  &&.no-margin {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    &&,
    &&.no-margin {
      margin: 0 auto 15px auto;
    }
  }
`
const ResponsiveStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      margin: 0;
      padding: 20px;
      max-width: 100%;
      text-align: center;
    }

    && h3 {
      font-size: 20px;
      margin-top: 10px;
    }

    && p {
      font-size: 18px;
    }
  }
`

const ItemStyled = styled(Grid)`
  height: 100%;
  padding: 30px;
  max-width: 90%;
  text-align: left;
  border-radius: 8px;
  transition: all ease 0.5s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px;
  color: ${({ theme }) => theme.palette.dark};
  background: ${({ theme }) => theme.palette.white};

  ${({ theme }) => theme.breakpoints.up('xs')} {
    &&:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 40px;
    }

    &&:hover h3 {
      color: ${({ theme }) => theme.palette.green};
    }

    &&:hover svg {
      padding: 8px;
      transform: scale(1.5);
      fill: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.dark};
      background: ${({ theme }) => theme.palette.lightGreen};
    }
  }

  svg {
    max-width: 40px;
    border-radius: 50%;
    transition: all ease 0.5s;
  }

  h3 {
    font-size: 23px;
    margin: 0 0 15px 0;
  }

  ${ResponsiveStyles};
`

const WHY_TRANSWORLD = InfoLinkContent.find(
  item => item.type === INFO_LINKS.WHY_TRANSWORLD
)

const WhyTransWorldContainer = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &&:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ;
  }

  .content-container {
    position: relative;
    margin: 0 auto;
  }

  .section-header {
    text-align: left;
  }
`

const ImageSection = styled.div`
  && {
    max-width: 960px;
    margin: 0 auto 65px auto;
    background: #cc3f0c;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    height: 100%;
    background-image: url('https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=10');
  }

  && p {
    position: relative;
    font-size: 20px;
  }

  &&:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ;
    background: #cc3f0c;
    opacity: 0.85;
  }

  ${ResponsiveStyles};
`

const AboutUsContainerStyled = styled(MaxWidthContainer)`
  ${PaddedContent};
  ${BorderBottom};

  p {
    font-size: 18px;
    line-height: 1.5;
  }

  .image-container {
    background: url(https://images.unsplash.com/photo-1491438590914-bc09fcaaf77a);
    background-size: cover;
    background-position: center;
  }

  .text-container {
    padding-left: 35px;
  }
`

const WhyTWorld = () => {
  return (
    <WhyTransWorldContainer>
      <AboutUsContainerStyled container className="content-container">
        <Grid item md={12}>
          <Grid container>
            <Grid item md={7}>
              <AnimationSlideUp>
                <SectionHeader
                  className="section-header"
                  text={WHY_TRANSWORLD.short}
                  postText={WHY_TRANSWORLD.description}
                />
                <StyledGrid container>
                  <Grid container>
                    <GridItemStyled item xs={12} md={6}>
                      <ItemStyled container>
                        <Grid item xs={12} md={3}>
                          <LowCost />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <h3>Low Cost Investment</h3>
                          <p>
                            No need for high street lease, stock or expensive
                            plant and equipment.
                          </p>
                        </Grid>
                      </ItemStyled>
                    </GridItemStyled>
                    <GridItemStyled item xs={12} md={6}>
                      <ItemStyled container>
                        <Grid item xs={12} md={3}>
                          <Balance />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <h3>A work life balance</h3>
                          <p>
                            You create your schedule and work when it suits to
                            get your work/life balance at home.
                          </p>
                        </Grid>
                      </ItemStyled>
                    </GridItemStyled>

                    <GridItemStyled item xs={12} md={6}>
                      <ItemStyled container>
                        <Grid item xs={12} md={3}>
                          <WorldSupport />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <h3>Local and International support</h3>
                          <p>
                            Enjoy the benefits of being part of a large
                            franchise group
                          </p>
                        </Grid>
                      </ItemStyled>
                    </GridItemStyled>
                    <GridItemStyled item xs={12} md={6}>
                      <ItemStyled container>
                        <Grid item xs={12} md={3}>
                          <Profit />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <h3>High Profit Potential</h3>
                          <p>
                            If you put in the work and follow the systems, your
                            business will have a significant income.
                          </p>
                        </Grid>
                      </ItemStyled>
                    </GridItemStyled>
                    <GridItemStyled className="no-margin" item xs={12} md={6}>
                      <ItemStyled container>
                        <Grid item xs={12} md={3}>
                          <Experience />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <h3>No experience required</h3>
                          <p>
                            Just an interest in business and a willingness to
                            learn and follow systems.
                          </p>
                        </Grid>
                      </ItemStyled>
                    </GridItemStyled>
                    <GridItemStyled className="no-margin" item xs={12} md={6}>
                      <ItemStyled container>
                        <Grid item xs={12} md={3}>
                          <WorldWide />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <h3>Be part of a local/worldwide team</h3>
                          <p>
                            580 brokers in 12 countries and growing every year.
                          </p>
                        </Grid>
                      </ItemStyled>
                    </GridItemStyled>
                  </Grid>
                </StyledGrid>
              </AnimationSlideUp>
            </Grid>
            <Grid item md={5}>
              <ImageSection>{WHY_TRANSWORLD.text}</ImageSection>
            </Grid>
          </Grid>
        </Grid>
      </AboutUsContainerStyled>
    </WhyTransWorldContainer>
  )
}

export default WhyTWorld
