import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Button } from '@material-ui/core'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
`

const StyledButton = styled(Button)(
  ({ theme }) => `
    && {
      width: 36px;
      display: flex;
      min-width: 0px;
      align-items: center;
      justify-content: center;
      background-color: ${theme.palette.white};
      border: 2px solid ${theme.palette.brandGold};
    }
  `
)

const StyledArrow = styled(ChevronRight)`
  && {
    width: 30px;
    height: 42px;
    transform: rotate(${({ flip }) => (flip === 'true' ? '180deg' : '0deg')});

    path:first-of-type {
      fill: ${({ theme }) => theme.palette.brandGold};
    }
  }
`

const DirectionalButton = ({ className, flip, onClick }) => {
  return (
    <Wrapper className={className}>
      <StyledButton onClick={onClick}>
        <StyledArrow flip={`${flip}`} />
      </StyledButton>
    </Wrapper>
  )
}

DirectionalButton.propTypes = {
  flip: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default DirectionalButton
