import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

const ButtonStyled = styled(Button)`
  && {
    min-height: 44px;
    box-shadow: none;
    text-transform: unset;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
`

const TworldButton = ({ text, ...other }) => {
  return (
    <ButtonStyled
      size="large"
      fullWidth
      variant="contained"
      color="primary"
      {...other}>
      {text}
    </ButtonStyled>
  )
}

TworldButton.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TworldButton
