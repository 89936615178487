import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import styled from 'styled-components'
import { AppBar, Toolbar } from '@material-ui/core'
import React, { memo, Fragment, useState } from 'react'

import LogoDark from '../../atoms/images/LogoDark'
import LogoLight from '../../atoms/images/LogoLight'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { HambugerMenu, CloseIcon } from '../../atoms/images/SVGIcons'
import DropDownMenu from '../../atoms/dialog/DropDownMenu'
import RequestInfoDialog from '../../atoms/dialog/RequestInfoDialog'

const AppBarStyled = styled(AppBar)`
  && {
    box-shadow: none;
    background: transparent;
    margin: 0 auto 15px auto;
    padding: 10px 15px 5px 15px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      padding-top: 15px;
    }
  }
`

const MaxWidthStyled = styled(MaxWidthContainer)`
  width: 100%;
`

const ToolbarStyled = styled(Toolbar)`
  && {
    padding: 0;
    color: ${({ theme }) => theme.palette.white};
  }

  a {
    cursor: pointer;
    margin-right: 15px;
    transition: border 0.25s ease;
  }

  a:hover {
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrey};
  }

  &&.dark {
    color: ${({ theme }) => theme.palette.black};
  }
`

const LogoContainerStyled = styled.div`
  flex-grow: 1;
`

const LogoStyled = styled.div`
  width: 220px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      max-width: 180px;
    }
  }
`

const HamburgerMenuStyled = styled(HambugerMenu)`
  opacity: 0.9;
  cursor: pointer;
  fill: ${({ theme, darkLogo }) =>
    darkLogo ? theme.palette.black : theme.palette.white};
`

const PrimaryHeader = memo(({ position, width, darkLogo }) => {
  const [dropDownVisibility, setDropDownVisibility] = useState(false)
  function showDropDown() {
    setDropDownVisibility(true)
  }

  function hideDropDown() {
    setDropDownVisibility(false)
  }

  const LINKS = (
    <Fragment>
      <Link
        onClick={hideDropDown}
        activeClass="active"
        to="services"
        hashSpy
        spy
        smooth
        offset={-50}
        duration={500}>
        Services
      </Link>
      <Link
        onClick={hideDropDown}
        activeClass="active"
        to="our-process"
        hashSpy
        spy
        smooth
        offset={-50}
        duration={500}>
        Process
      </Link>
      <Link
        onClick={hideDropDown}
        activeClass="active"
        to="our-team"
        hashSpy
        spy
        smooth
        offset={-50}
        duration={500}>
        Team
      </Link>
      <Link
        onClick={hideDropDown}
        activeClass="active"
        to="contact"
        hashSpy
        spy
        smooth
        offset={-50}
        duration={500}>
        Contact Us
      </Link>
    </Fragment>
  )

  return (
    <AppBarStyled position={position}>
      <MaxWidthStyled>
        <ToolbarStyled className={darkLogo ? 'dark' : 'light'}>
          <LogoContainerStyled>
            <LogoStyled>{darkLogo ? <LogoDark /> : <LogoLight />}</LogoStyled>
          </LogoContainerStyled>
          {isWidthUp('sm', width) ? (
            <Fragment>
              {LINKS}
              <RequestInfoDialog />
            </Fragment>
          ) : (
            <Fragment>
              <HamburgerMenuStyled darkLogo={darkLogo} onClick={showDropDown} />
              <DropDownMenu
                isOpen={dropDownVisibility}
                hideDropDown={hideDropDown}>
                <ToolbarStyled>
                  <LogoContainerStyled>
                    <LogoStyled>
                      <LogoDark />
                    </LogoStyled>
                  </LogoContainerStyled>
                  <CloseIcon onClick={hideDropDown} />
                </ToolbarStyled>
                {LINKS}
              </DropDownMenu>
            </Fragment>
          )}
        </ToolbarStyled>
      </MaxWidthStyled>
    </AppBarStyled>
  )
})

PrimaryHeader.propTypes = {
  darkLogo: PropTypes.bool,
  position: PropTypes.string,
  width: PropTypes.string.isRequired,
}

PrimaryHeader.defaultProps = {
  position: 'fixed',
}

export default withWidth()(PrimaryHeader)
