import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import SectionHeader from '../../atoms/content/SectionHeader'
import InfoLinkContent from '../../../data/InfoLinkContent'
import * as INFO_LINKS from '../../../data/infoLinks.json'
import MaxWidthContainer from '../../atoms/container/MaxWidthContainer'
import AnimationSlideUp from '../../atoms/content/AnimationSlideUp'
import {
  Collaboration,
  Wallet,
  PaymentMethod,
} from '../../atoms/images/SVGIcons'
import PaddedContent from '../../atoms/container/PaddedContent'

const WHAT_IT_COST = InfoLinkContent.find(
  item => item.type === INFO_LINKS.WHAT_IT_COST
)

const ContentContainer = styled.div`
  ${PaddedContent};
  font-size: 18px;
  color: ${({ theme }) => theme.palette.white};
  background: linear-gradient(#19231a 30%, #283048 90%);

  && h2 {
    color: ${({ theme }) => theme.palette.brandGold};
  }
`

const GridItemStyled = styled(Grid)`
  && {
    padding: 35px;
  }

  && p {
    margin: 0;
    line-height: 1.5;
  }

  && svg {
    max-width: 45px;
    fill: ${({ theme }) => theme.palette.white};
  }

  a {
    font-weight: bold;
    text-decoration: underline;
    margin-left: 5px;
    position: relative;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      padding: 35px 0;
      text-align: center;
    }
  }
`

const SectionHeaderStyled = styled(SectionHeader)`
  && {
    max-width: 600px;
    text-align: center;
    margin: 0 auto 45px auto;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    && {
      text-align: left;
    }
  }
`

const WhatItCost = () => {
  return (
    <ContentContainer>
      <MaxWidthContainer container>
        <AnimationSlideUp>
          <SectionHeaderStyled
            text={WHAT_IT_COST.short}
            postText={WHAT_IT_COST.description}
          />
          <Grid container>
            <GridItemStyled item md={4}>
              <Collaboration />
              <h3>Complete Business Broker system</h3>
              <p>
                Franchises are becoming an increasingly popular option as a fast
                track to business ownership throughout New Zealand, and the
                industry is expected to continue to grow making this the perfect
                time to invest in a business broker franchise such as Transworld
                Business Advisors. What’s not to like, with all the Systems,
                Software, Documentation and Training & Support provided.
              </p>
            </GridItemStyled>

            <GridItemStyled item md={4}>
              <Wallet />
              <h3>How much do you need?</h3>
              <p>
                Like most businesses, there is a startup phase. It can take time
                to identify a business for sale, market it and then complete the
                transaction so we recommend that you have access to working
                capital/funds to cover 6 months of your business & personal
                costs. You might not need that much but it is there if you do.
              </p>
            </GridItemStyled>
            <GridItemStyled item md={4}>
              <PaymentMethod />
              <h3>Franchise Fee</h3>
              <p>
                The franchise fee is $89,500 + GST. You will receive a franchise
                territory, two weeks training in
                Florida in the USA and an advertising/supplies package.
              </p>
              <p>
                You will also have access to ongoing support required to make
                your business successful. Limited areas are available -
                <a href="#contact">ENQUIRE NOW</a> to find out more.
              </p>
            </GridItemStyled>
          </Grid>
        </AnimationSlideUp>
      </MaxWidthContainer>
    </ContentContainer>
  )
}

export default WhatItCost
